import service from '../../utils/request'

// 查询对接配置
export function findGZJWDockingByTaskId(taskId) {
  return service({
    method: 'get',
    url: `/gz-docking/findByTaskId`,
    params: {
      taskId,
    },
  })
}
// 新增对接
export function addGZJWDocking(data) {
  return service({
    method: 'post',
    url: `/gz-docking/add`,
    data,
  })
}
// 修改
export function updateGZJWDocking(data) {
  return service({
    method: 'put',
    url: `/gz-docking/update`,
    data,
  })
}
// 删除
export function deleteGZJWDocking(id) {
  return service({
    method: 'delete',
    url: `/gz-docking/delete`,
    params: { id },
  })
}

export function syncPoint(id) {
  return service({
    method: 'get',
    url: `/gz-docking/syncPoint`,
    params: { id },
  })
}
export function uploadStartLog(id) {
  return service({
    method: 'get',
    url: `/gz-docking/uploadStartDeviceLog`,
    params: { id },
  })
}
export function uploadStopLog(id) {
  return service({
    method: 'get',
    url: `/gz-docking/uploadStopDeviceLog`,
    params: { id },
  })
}



