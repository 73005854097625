<template>
  <el-tabs type="card">
    <el-tab-pane label="对接配置">
      <div>
        <el-empty description="当前没有对接配置哦" v-show="!isFlag" ref="ruleForm">
          <el-button type="text" @click="isFlag = true">立即创建</el-button>
        </el-empty>
        <el-form ref="form" :model="form" :rules="rules" label-width="150px" v-show="isFlag">
          <el-form-item label="对接平台账号" prop="accountNumber">
            <el-input v-model="form.accountNumber"></el-input>
          </el-form-item>
          <el-form-item label="对接平台密码" prop="password">
            <el-input v-model="form.password"></el-input>
          </el-form-item>
          <el-form-item label="对接项目Id" prop="projectId">
            <el-input v-model="form.projectId"></el-input>
          </el-form-item>
          <el-form-item label="设备Id" prop="deviceId">
            <el-input v-model="form.deviceId"></el-input>
          </el-form-item>

          <el-form-item label="监测方" prop="businessType">
            <el-select v-model="form.businessType" placeholder="选择监测方" style="width: 100%">
              <el-option label="施工监测" value="2" />
              <el-option label="第三方监测" value="1" />
            </el-select>
          </el-form-item>

          <el-form-item label="监测编号V" prop="projectNumV" v-show="form.pkDockingId">
            <el-input v-model="form.projectNumV"></el-input>
          </el-form-item>
          <el-form-item label="监测编号F" prop="projectNumF" v-show="form.pkDockingId">
            <el-input v-model="form.projectNumF"></el-input>
          </el-form-item>
          <el-form-item label="监测编号L" prop="projectNumL" v-show="form.pkDockingId">
            <el-input v-model="form.projectNumL"></el-input>
          </el-form-item>
          <el-form-item label="监测编号H" prop="projectNumH" v-show="form.pkDockingId">
            <el-input v-model="form.projectNumH"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submitForm">{{ form.pkDockingId ? '修改' : '立即创建' }}</el-button>
            <el-button @click="open('del')" type="danger" v-show="form.pkDockingId">删除</el-button>
            <el-button @click="open('send')" type="success" v-show="form.pkDockingId">发送</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-tab-pane>
    <el-tab-pane label="对接日志">
      <el-button type="primary" size="small" icon="el-icon-refresh-left" v-if="tableData.length > 0"
        @click="findDockingLog">刷新</el-button>
      <el-table :data="tableData" style="width: 100%" v-if="tableData.length > 0" v-loading="loading">
        <el-table-column prop="createTime" label="时间"> </el-table-column>
        <el-table-column prop="dockingLog" label="日志"> </el-table-column>
      </el-table>
      <el-empty description="无日志" v-else>
        <el-button type="primary" size="small" icon="el-icon-refresh-left" @click="findDockingLog">刷新</el-button>
      </el-empty>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { findDockingByTaskId, addDockingBySubway, updateDockingBySubway, deleteDockingBySubway, sendDockingBySubway, findDockingLogByTaskId } from '@/api/docking/subway'
const form = {
  accountNumber: '',
  password: '',
  projectId: '',
  deviceId: '',
  projectNumV: '',
  projectNumF: '',
  projectNumL: '',
  projectNumH: '',
}
export default {
  data() {
    return {
      tableData: [],
      loading: false,
      isFlag: false,
      send: {},
      form,
      rules: {
        accountNumber: [{ required: true, message: '对接平台账号不能为空' }],
        password: [{ required: true, message: '对接平台密码不能为空' }],
        projectId: [{ required: true, message: '对接项目Id不能为空' }],
        deviceId: [{ required: true, message: '设备Id不能为空' }],
        // projectNumV: [{ required: true, message: '对接监测编号不能为空' }],
        // projectNumF: [{ required: true, message: '对接监测编号不能为空' }],
        // projectNumH: [{ required: true, message: '对接监测编号不能为空' }],
        // projectNumL: [{ required: true, message: '对接监测编号不能为空' }],
      },
    }
  },
  props: {
    pkTaskId: {
      type: Number,
    },
  },
  methods: {
    async findDockingByTaskIdFun() {
      const { data: res } = await findDockingByTaskId(this.pkTaskId)
      console.log(res.data)
      this.isFlag = res.data.length === 0 ? false : true
      this.send = res.data.length === 0 ? {} : res.data[0]
      if (res.data.length > 0) {
        const { pkDockingId, accountNumber, password, projectId, projectNum, deviceId } = res.data[0]

        if (projectNum) {
          const arr = projectNum.split(';')
          this.form = { pkDockingId, accountNumber, password, projectId, deviceId, projectNumV: arr[0] || '', projectNumF: arr[1] || '', projectNumL: arr[2] || '', projectNumH: arr[3] || '' }
        } else {
          this.form = { pkDockingId, accountNumber, password, projectId, deviceId, projectNumV: '', projectNumF: '', projectNumL: '', projectNumH: '' }
        }
      }
    },
    async dockingBySubwayFun() {
      const { pkDockingId, accountNumber, password, projectId, deviceId,businessType } = this.form
      const form = { pkDockingId, fkTaskId: this.pkTaskId, accountNumber, password, projectId, deviceId,businessType }


      const projectNum = this.form.projectNumV + ';' + this.form.projectNumF + ';' + this.form.projectNumH + ';' + this.form.projectNumL

      const { data: res } = form.pkDockingId ? await updateDockingBySubway({projectNum,...form}) : await addDockingBySubway(form)
      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      res.code === 200 ? this.findDockingByTaskIdFun() : null
    },
    async delDockingBySubwayFun() {
      const { data: res } = await deleteDockingBySubway(this.form.pkDockingId)
      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      res.code === 200 ? this.findDockingByTaskIdFun() : null
    },
    async sendDockingBySubwayFun() {
      const { data: res } = await sendDockingBySubway(this.send)
      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
    },
    submitForm() {
      if (this.form.accountNumber === '' || this.form.password === '' || this.form.projectId === '') {
        return
      }
      this.dockingBySubwayFun()
    },
    open(type) {
      const text = type === 'del' ? '此操作将删除该配置, 是否继续?' : '是否发送数据?'
      this.$confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          type === 'del' ? this.delDockingBySubwayFun() : this.sendDockingBySubwayFun()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    async findDockingLog() {
      this.loading = true
      const { data: res } = await findDockingLogByTaskId({ id: this.pkTaskId })
      this.tableData = res
      this.loading = false
    },
  },
  created() {
    this.findDockingByTaskIdFun()
  },
  mounted() { },
}
</script>

<style scoped lang="scss"></style>
