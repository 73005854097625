import service from '../utils/request'

// Type F L H V
// 查询 任务中 测点
export function findAllPoint(taskId) {
  return service({
    method: 'get',
    url: '/taskMonitoringPoint3/findByTaskId',
    params: {
      taskId,
    },
  })
}
export function findAllPointV3(taskId) {
  return service({
    method: 'get',
    url: '/taskMonitoringPoint3/findByTaskId',
    params: {
      taskId,
    },
  })
}
// 根据类型 查询 任务中 测点
export function findPointByType(taskId, type) {
  return service({
    method: 'get',
    url: '/taskMonitoringPoint3/findByType',
    params: {
      taskId,
      type,
    },
  })
}

// 根据类型 查询 任务中 测点
export function findPointByTypeV3(taskId, type) {
  return service({
    method: 'get',
    url: '/taskMonitoringPoint3/findByType',
    params: {
      taskId,
      type,
    },
  })
}
// 测点详情
export function findPointById(id) {
  return service({
    method: 'get',
    url: '/taskMonitoringPoint3/findById',
    params: {
      id,
    },
  })
}

// 添加测点
export function addPoint(pointName, fkTaskId, fkNodeId, monitoringType) {
  return service({
    method: 'POST',
    url: '/taskMonitoringPoint3/addTaskMonitoringPoint',
    data: {
      pointName,
      fkTaskId,
      fkNodeId,
      // nodeNumber,
      monitoringType,
    },
  })
}

// 批量添加测点
export function addPointList(taskId, pointName, type, startValue, endValue) {
  return service({
    method: 'POST',
    url: '/taskMonitoringPoint3/addPoints',
    data: {
      taskId,
      pointName,
      type,
      startValue,
      endValue,
    },
  })
}

// 删除测点
export function delPoint(id) {
  return service({
    method: 'DELETE',
    url: '/taskMonitoringPoint3/removeTaskMonitoringPoint',
    params: {
      id,
    },
  })
}

// 批量删除测点
export function delPointList(ids) {
  return service({
    method: 'DELETE',
    url: '/taskMonitoringPoint3/removePoints',
    params: {
      ids,
    },
  })
}


// 批量修改特征长度
export function updateLength(params) {
  return service({
    method: 'PUT',
    url: '/taskMonitoringPoint3/updateLength',
    params
  })
}

// 修改测点 的 节点
export function updatePointNode(pkItemId, fkNodeId) {
  return service({
    method: 'put',
    url: '/taskMonitoringPoint3/banding',
    data: {
      pkItemId,
      fkNodeId,
    },
  })
}
// 修改测点信息
export function updatePointInfo(info) {
  return service({
    method: 'put',
    url: '/taskMonitoringPoint3/updateTaskMonitoringPoint',
    data: info,
  })
}

// 修改 轴力 量程
export function updateTaskSpan(taskId, range) {
  return service({
    method: 'put',
    url: '/taskMonitoringPoint3/updateRange',
    params: {
      taskId,
      range,
    },
  })
}

// 测点的批量操作
// 批量设置报警预警值
export function updatePointsAlarm(taskId, type, warning, alarm) {
  return service({
    method: 'put',
    url: '/taskMonitoringPoint3/updatePoints',
    params: {
      taskId,
      type,
      warning,
      alarm,
    },
  })
}

// 批量设置初始值
export function updatePointsValue(taskId, type) {
  return service({
    method: 'put',
    url: '/taskMonitoringPoint3/updateValue',
    params: {
      taskId,
      type,
    },
  })
}

// 数据查询---------------------------------------------------------------------------------------------------
