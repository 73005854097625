import service from '../../utils/request'

// 查询对接配置
export function findDockingByTaskId(taskId) {
  return service({
    method: 'get',
    url: `/subway/findDockingByTaskId`,
    params: {
      taskId,
    },
  })
}
// 新增对接
export function addDockingBySubway(data) {
  return service({
    method: 'post',
    url: `/subway/addDocking`,
    data,
  })
}
// 修改
export function updateDockingBySubway(data) {
  return service({
    method: 'put',
    url: `/subway/updateDocking`,
    data,
  })
}
// 删除
export function deleteDockingBySubway(id) {
  return service({
    method: 'delete',
    url: `/subway/delete`,
    params: { id },
  })
}
//   发送数据
export function sendDockingBySubway(data) {
  return service({
    method: 'post',
    url: `/subway/sendDocking`,
    data,
  })
}

//   发送报警数据
export function sendAlarmBySubway(data) {
  return service({
    method: 'get',
    url: `/subway/sendAlarmDocking`,
    params: data,
  })
}

//   发送报警数据
export function findDockingLogByTaskId(data) {
  return service({
    method: 'get',
    url: `/subwayDockingLog/findByTaskId`,
    params: data,
  })
}
