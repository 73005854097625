<template>
  <div class="nodeManager">
    <el-row>
      <el-button-group>
        <el-button type="primary" v-show="version === 0" @click="dialogShow(2)" v-permission="2">批量迁移V2</el-button>
        <el-button type="primary" v-show="version === 1" @click="dialogShow(2.5)" v-permission="2">批量迁移V2.5</el-button>
        <el-button type="primary" v-show="version === 2" @click="dialogShow(3)" v-permission="2">批量迁移V3</el-button>
        <el-select class="" v-model="version" placeholder="请选择产品版本">
          <el-option label="version_2.0" :value="0"></el-option>
          <el-option label="version_2.5" :value="1"></el-option>
          <el-option label="version_3" :value="2"></el-option>
        </el-select>
      </el-button-group>

      <el-select class="host left_10" v-model="hostId" filterable placeholder="请选择主机">
        <el-option v-for="item in hostList" :key="item.pkHostId" :label="item.hostNumber" :value="item.pkHostId">
          <p>
            {{ item.hostNumber }}
            <span style="color: green; float: right" v-show="$store.state.power === 4">{{ item.company.companyName }}</span>
          </p>
        </el-option>
      </el-select>

      <el-select class="left_10" v-model="taskId" filterable clearable placeholder="请选择任务">
        <el-option v-for="item in taskList" :key="item.pkTaskId" :label="item.projectName" :value="item.pkTaskId"></el-option>
      </el-select>

      <el-select class="left_10" v-model="state" placeholder="是否展示在线状态">
        <el-option label="展示在线状态" :value="1"></el-option>
        <el-option label="隐藏在线状态" :value="0"></el-option>
      </el-select>

      <el-select class="left_10" v-model="type" placeholder="设备类型" v-show="version === 2" @change="findAllNodeByHostFun">
        <el-option label="查看节点" value="节点"></el-option>
        <el-option label="查看网关" value="网关"></el-option>
      </el-select>
    </el-row>
    <MyTable
      :dataSource="dataSource"
      :columns="columns"
      :stripe="true"
      :selection="false"
      :loading="loading"
      @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      :total="total"
      :currentPage="currentPage"
      :pageSize="pageSize"
      @handleSelectionChange="handleSelectionChange"
      @handleClick="handleClick"
    >
      <el-table-column prop="openOrEnd" label="远程控制" width="120" align="center" v-if="version !== 0">
        <template slot-scope="scope">
          <el-button type="success" :disabled="version === 0" size="mini" v-show="scope.row.onlineStatus !== '在线'" @click="switchNodeFunc(scope.row, 0)">远程启动</el-button>
          <el-button type="info" :disabled="version === 0" size="mini" v-show="scope.row.onlineStatus === '在线'" @click="switchNodeFunc(scope.row, 1)">远程关闭</el-button>
        </template>
      </el-table-column>
    </MyTable>
    <el-dialog title="主机节点迁移V2" class="TransferNode" center :visible.sync="dialogVisibleNodeList" width="940px" :before-close="handleClose">
      <TransferNode :hostId="hostId" v-if="dialogVisibleNodeList"></TransferNode>
    </el-dialog>

    <el-dialog title="主机节点迁移V2.5" class="TransferNode" center :visible.sync="dialogVisibleNodeListV2" width="940px" :before-close="handleClose">
      <TransferNode2 :hostId="hostId" v-if="dialogVisibleNodeListV2"></TransferNode2>
    </el-dialog>
    <el-dialog title="主机网关迁移V3" class="TransferNode" center :visible.sync="dialogVisibleNodeListV3" width="940px" :before-close="handleClose">
      <TransferNode3 :hostId="hostId" v-if="dialogVisibleNodeListV3"></TransferNode3>
    </el-dialog>

    <el-dialog title="修改节点状态" class="upState" center :visible.sync="dialogVisibleRunningState" width="500px" height="500px" :before-close="handleClose">
      <UpdateNodeRunState :nodeId="pkNodeId" v-if="dialogVisibleRunningState"></UpdateNodeRunState>
    </el-dialog>
  </div>
</template>

<script>
import MyTable from '@/components/MyTable.vue'
import { findHostByUnit, findAllHost, findHostByUserIdHost, findHostByUserIdUser } from '@/api/Host.js'
import { findTaskByHostId } from '@/api/Task.js'
import { findNodeList, findNode2List, handleSwitchNode } from '@/api/Node.js'

import { findNode3List, handleSwitchNode3 } from '@/api/NodeV3.js'

import TransferNode from './TransferNode.vue'
import TransferNode2 from './TransferNode2.5.vue'
import TransferNode3 from './TransferNode3.vue'
import UpdateNodeRunState from './UpdateNodeRunState.vue'

const columns = [
  {
    prop: 'nodeNumber',
    label: '节点编号',
    width: 180,
    type: 'text',
    align: 'center',
  },
  {
    prop: 'simCard',
    label: 'sim卡',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'nodeType',
    label: '节点类型',
    width: 100,
    type: 'text',
    align: 'center',
  },
  {
    prop: 'onlineStatus',
    label: '在线状态',
    width: 150,
    type: 'text',
    align: 'center',
  },
  {
    prop: 'reportingCycle',
    label: '自主上报周期',
    width: 120,
    type: 'text',
    align: 'center',
  },
  {
    prop: 'latitude',
    label: '经度',
    type: 'text',
    align: 'center',
  },

  {
    prop: 'latitude',
    label: '纬度',
    type: 'text',
    align: 'center',
  },

  {
    prop: 'electricQuantity',
    label: '电量',
    type: 'text',
    align: 'center',
  },

  {
    prop: 'signalStrength',
    label: '信号强度',
    type: 'text',
    align: 'center',
  },

  {
    prop: 'hostNumber',
    label: '所属主机',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'projectName',
    label: '所在工程',
    type: 'text',
    align: 'center',
  },
  // {
  //   prop: 'runningState',
  //   label: '运行状态',
  //   width: 150,
  //   type: 'button',
  //   align: 'center',
  //   buttonInfo: {
  //     text: null,
  //     type: null,
  //     size: 'mini',
  //   },
  // },
]
const dataSource = []
export default {
  components: {
    MyTable,
    TransferNode,
    TransferNode2,
    TransferNode3,
    UpdateNodeRunState,
  },
  data() {
    return {
      version: 0,
      // 单位
      unitList: [],
      fkCompanyId: null,
      // 主机
      hostList: [],
      hostId: null,
      taskId: null,
      state: 0,
      // 表
      selection: false,
      index: false,
      currentPage: 0,
      pageSize: 10,
      total: 0,
      loading: true,
      dataSource,
      // 批量删除操作
      nodeIdList: [],
      // 批量删除提示信息
      delListTip: '',
      columns,
      // 弹窗
      dialogVisibleNode: false,
      dialogVisibleNodeList: false,
      dialogVisibleNodeListV2: false,
      dialogVisibleNodeListV3: false,
      dialogVisibleRunningState: false,
      pkNodeId: null,
      nodeId: null,
      taskList: [],
      type: '节点',
    }
  },
  watch: {
    // fkCompanyId() {
    //   this.findAllHostFun()
    // },
    version(e) {
      this.getColumns(e)
      this.findAllNodeByHostFun()
    },
    hostId() {
      this.findAllNodeByHostFun()
      this.findTaskByHostIdFun()
    },
    state() {
      this.getColumns(this.version)
      this.findAllNodeByHostFun()
    },
    taskId() {
      this.findAllNodeByHostFun()
    },
  },

  methods: {
    getColumns(e) {
      if (e === 0) {
        this.columns = [
          {
            prop: 'nodeNumber',
            label: '节点编号',
            width: 180,
            type: 'text',
            align: 'center',
          },
          {
            prop: 'simCard',
            label: 'sim卡',
            type: 'text',
            align: 'center',
          },
          {
            prop: 'nodeType',
            label: '节点类型',
            width: 100,
            type: 'text',
            align: 'center',
          },
          {
            prop: 'onlineStatus',
            label: '在线状态',
            width: 150,
            type: 'text',
            align: 'center',
          },
          {
            prop: 'reportingCycle',
            label: '自主上报周期',
            width: 120,
            type: 'text',
            align: 'center',
          },

          {
            prop: 'hostNumber',
            label: '所属主机',
            type: 'text',
            align: 'center',
          },
          {
            prop: 'projectName',
            label: '所在工程',
            type: 'text',
            align: 'center',
          },
          // {
          //   prop: 'runningState',
          //   label: '运行状态',
          //   width: 150,
          //   type: 'button',
          //   align: 'center',
          //   buttonInfo: {
          //     text: null,
          //     type: null,
          //     size: 'mini',
          //   },
          // },
        ]
      } else if (e === 1) {
        this.columns = [
          {
            prop: 'nodeNumber',
            label: '节点编号',
            width: 180,
            type: 'text',
            align: 'center',
          },
          {
            prop: 'simCard',
            label: 'sim卡',
            type: 'text',
            align: 'center',
          },
          {
            prop: 'nodeType',
            label: '节点类型',
            width: 100,
            type: 'text',
            align: 'center',
          },
          {
            prop: 'onlineStatus',
            label: '在线状态',
            width: 150,
            type: 'text',
            align: 'center',
          },
          {
            prop: 'reportingCycle',
            label: '自主上报周期',
            width: 120,
            type: 'text',
            align: 'center',
          },
          {
            prop: 'latitude',
            label: '经度',
            type: 'text',
            align: 'center',
          },

          {
            prop: 'latitude',
            label: '纬度',
            type: 'text',
            align: 'center',
          },

          {
            prop: 'electricQuantity',
            label: '电量(%)',
            type: 'text',
            align: 'center',
          },

          {
            prop: 'signalStrength',
            label: '信号强度',
            type: 'text',
            align: 'center',
          },

          {
            prop: 'hostNumber',
            label: '所属主机',
            type: 'text',
            align: 'center',
          },
          {
            prop: 'projectName',
            label: '所在工程',
            type: 'text',
            align: 'center',
          },
          // {
          //   prop: 'runningState',
          //   label: '运行状态',
          //   width: 150,
          //   type: 'button',
          //   align: 'center',
          //   buttonInfo: {
          //     text: null,
          //     type: null,
          //     size: 'mini',
          //   },
          // },
        ]
      } else if (e === 2) {
        this.columns = [
          {
            prop: 'gatewayNumber',
            label: '所属网关',
            width: 180,
            type: 'text',
            align: 'center',
          },
          {
            prop: 'nodeNumber',
            label: '节点编号',
            width: 180,
            type: 'text',
            align: 'center',
          },

          {
            prop: 'simCard',
            label: 'sim卡',
            type: 'text',
            align: 'center',
          },
          {
            prop: 'nodeType',
            label: '节点类型',
            width: 100,
            type: 'text',
            align: 'center',
          },
          {
            prop: 'onlineStatus',
            label: '在线状态',
            width: 150,
            type: 'text',
            align: 'center',
          },
          {
            prop: 'reportingCycle',
            label: '自主上报周期',
            width: 120,
            type: 'text',
            align: 'center',
          },

          {
            prop: 'electricQuantity',
            label: '电量(%)',
            type: 'text',
            align: 'center',
          },

          {
            prop: 'signalStrength',
            label: '接收信号强度(dBM)',
            type: 'text',
            align: 'center',
          },

          {
            prop: 'hostNumber',
            label: '所属主机',
            type: 'text',
            align: 'center',
          },
          {
            prop: 'projectName',
            label: '所在工程',
            type: 'text',
            align: 'center',
          },
          // {
          //   prop: 'runningState',
          //   label: '运行状态',
          //   width: 150,
          //   type: 'text',
          //   align: 'center',
          // },
        ]
      }

      if (this.state === 0) {
        this.columns = this.columns.filter((item) => item.prop !== 'onlineStatus')
      }
    },
    async findHostByUser() {
      if (this.$store.state.power === 4) {
        return await findAllHost(0, 9999)
      }
      if (this.$store.state.power === 3) {
        return await findHostByUnit(this.$store.state.unitId, 0, 9999)
      }
      if (this.$store.state.power === 2) {
        return await findHostByUserIdHost(this.$store.state.userId, 0, 9999)
      }
      if (this.$store.state.power === 1) {
        return await findHostByUserIdUser(this.$store.state.userId, 0, 9999)
      }
    },
    // 根据不同用户身份查询用户主机
    async findAllHostByUserFun() {
      const { data: res } = await this.findHostByUser()
      this.hostList = res.data.list || res.data
      this.hostList.forEach((item) => {
        item.companyName = item.company.companyName
      })

      this.hostId = this.$route.params.hostId || this.hostList[0].pkHostId
    },
    async findTaskByHostIdFun() {
      const { data: res } = await findTaskByHostId(null, this.hostId, 1, 100)
      this.taskList = res.data.list
    },
    async findAllNodeByHostFun() {
      this.loading = true

      if (this.version === 0) {
        const { data: res } = await findNodeList(this.hostId, this.taskId, this.state, this.currentPage, this.pageSize)
        // 查询无结果
        if (!res.data || res.data.list.length === 0) {
          this.dataSource = []
          this.loading = false
          return
        }
        // 有结果
        this.dataSource = res.data.list
        this.total = res.data.total
        this.dataSource.forEach((item) => {
          item.host ? (item.hostNumber = item.host.hostNumber) : null
          item.state ? (item.runningState = item.state.runningState) : null
          item.task ? (item.projectName = item.task.projectName) : null
          item.onlineStatus === 'ONLINE' ? (item.onlineStatus = '在线') : (item.onlineStatus = '离线')
        })
      } else if (this.version === 1) {
        const { data: res } = await findNode2List(this.hostId, this.taskId, this.state, this.currentPage, this.pageSize)
        this.dataSource = res.data.list

        this.dataSource.forEach((item) => {
          item.host ? (item.hostNumber = item.host.hostNumber) : null
          item.state ? (item.runningState = item.state.runningState) : null
          item.task ? (item.projectName = item.task.projectName) : null
          // item.onlineStatus === 'ONLINE' ? (item.onlineStatus = '在线') : (item.onlineStatus = '离线')
        })
        this.total = res.data.total
        // 查询无结果
        if (!res.data || res.data.list.length === 0) {
          this.dataSource = []
          this.loading = false
          return
        }
      } else if (this.version === 2) {
        const { data: res } = await findNode3List(this.hostId, this.taskId, this.state, this.currentPage, this.pageSize, this.type)
        this.dataSource = res.data.list

        this.dataSource.forEach((item) => {
          item.nodeNumber = item.number
          item.host ? (item.hostNumber = item.host.hostNumber) : null
          item.state ? (item.runningState = item.state.runningState) : null
          item.task ? (item.projectName = item.task.projectName) : null
          // item.onlineStatus === 'ONLINE' ? (item.onlineStatus = '在线') : (item.onlineStatus = '离线')
        })
        this.total = res.data.total
        // 查询无结果
        if (!res.data || res.data.list.length === 0) {
          this.dataSource = []
          this.loading = false
          return
        }
      }

      this.loading = false
    },

    async init() {
      await this.findAllHostByUserFun()
      await this.findTaskByHostIdFun()
      await this.findAllNodeByHostFun()
      this.loading = false
    },

    dialogShow(version) {
      if (version === 2) {
        this.dialogVisibleNodeList = true
      } else if (version === 2.5) {
        this.dialogVisibleNodeListV2 = true
      } else if (version === 3) {
        this.dialogVisibleNodeListV3 = true
      }

      // this.dialogVisibleNode = true
    },

    handleClick(row, type) {
      console.log(row, type)
      switch (type) {
        case 'runningState':
          this.pkNodeId = row.pkNodeId
          this.dialogVisibleRunningState = true
          break
      }
    },
    handleClose() {
      this.dialogVisibleNode = false
      this.dialogVisibleNodeList = this.dialogVisibleNodeListV2 = this.dialogVisibleNodeListV3 = false

      this.dialogVisibleRunningState = false

      // this.hostId = null
    },
    // 修改选择。。。
    handleSelectionChange(e) {
      this.nodeIdList = []
      e.forEach((item) => {
        this.nodeIdList.push(item.pkNodeId)
      })
      console.log(this.nodeIdList)
    },
    handleSizeChange(val) {
      this.pageSize = val
      console.log('this.pageSize', this.pageSize)
      this.findAllNodeByHostFun()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      console.log('this.currentPage', this.currentPage)
      this.findAllNodeByHostFun()
    },

    async switchNodeFunc(row, switchNode) {
      console.log(switchNode)
      const { data: res } = this.version === 1 ? await handleSwitchNode({ nodeId: row.pkNodeId, switchNode }) : await handleSwitchNode3({ node3Id: row.pkNodeId, switchNode })
      console.log(res)
      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      this.findAllNodeByHostFun()
    },
  },
  created() {
    this.getColumns(0)
    this.findAllHostByUserFun()
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.nodeManager {
  padding: 10px;
  width: calc(100% - 20px);

  .el-row {
    margin-top: 0px;
    margin-bottom: 10px;
    display: flex;
    .el-button-group {
      // display:flex;
      .el-select {
        float: left;
      }
    }

    .left_10 {
      margin-left: 10px;
    }
  }

  .table-wrapper {
    height: calc(100% - 120px);
    border-radius: 15px;
    // padding: 15px;

    .el-table {
      margin-bottom: 20px; 
    }
  }
}

.upState ::v-deep .el-dialog {
  width: 350px !important;
  height: 240px;
}

// .TransferNode ::v-deep .el-dialog {
//   width: 450px !important;
//   height: 400px;
// }
</style>
