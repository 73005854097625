<template>
  <div class="Data">
    <el-scrollbar>
      <el-tabs type="border-card" @tab-click="selectTab">
        <el-tab-pane label="监测数据">
          <el-row>
            <el-select v-model="itemType" filterable placeholder="选择检测项 查看下表最新数据">
              <el-option v-for="item in allGroupInfo" :key="item.itemType" :label="item.deviceProtocolName"
                :value="item.itemType"> </el-option>
            </el-select>

            <el-select style="margin-left: 10px" v-model="itemId" filterable placeholder="查看数据曲线">
              <el-option v-for="item in itemList" :key="item.pkItemId" :label="item.pointName" :value="item.pkItemId">
              </el-option>
            </el-select>

            <el-button class="el-icon-search mr_10" @click="findPointDataFunNew">测点查询</el-button>
            <el-button class="el-icon-document-copy mr_10" @click="findPointDataByGroupFunNew">同组查询</el-button>

            <el-date-picker class="mr_10" @change="handleChangeTime" :picker-options="pickerOptions" v-model="dayTime"
              type="datetimerange" unlink-panels format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :clearable="false">
            </el-date-picker>

            <el-button v-permission="2" type="" v-show="!isAbnormal" @click="isAbnormal = true"
              style="margin-left: 10px">处理异常</el-button>
            <el-button v-permission="2" class="el-icon-delete" type="danger" v-show="isAbnormal"
              @click="handleAbnormal">删除异常值</el-button>
            <el-button v-permission="2" type="" v-show="isAbnormal" @click="clearSelect()">取消</el-button>

            <el-button class="el-icon-download mr_10" type="success" @click="handleExport">测点下载</el-button>
            <el-button class="el-icon-download" type="success" @click="handleExport2">同组下载</el-button>



          </el-row>

          <PointList ref="myPoint" :dataSource="dataSource" :selection="isAbnormal" :protocol="protocol"
            :loading="loading" :total="total" :currentPage="currentPage" :pageSize="pageSize"
            @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"
            @handleSelectionChange="handleSelectionChange" @clearSelect="clearSelect"></PointList>
        </el-tab-pane>

        <el-tab-pane v-permission="2" label="原始数据">
          <el-row>
            <el-select v-model="itemType" filterable placeholder="选择检测项 查看下表最新数据">
              <el-option v-for="item in allGroupInfo2" :key="item.itemType" :label="item.deviceProtocolName"
                :value="item.itemType"> </el-option>
            </el-select>

            <el-select style="margin-left: 10px" v-model="itemId" filterable placeholder="查看数据曲线">
              <el-option v-for="item in itemList" :key="item.pkItemId" :label="item.pointName" :value="item.pkItemId">
              </el-option>
            </el-select>

            <el-button class="el-icon-search mr_10" @click="findPointDataFunNew">测点查询</el-button>
            <el-button class="el-icon-document-copy mr_10" @click="findPointDataByGroupFunNew">同组查询</el-button>

            <el-date-picker class="mr_10" @change="findPointDataFun" :picker-options="pickerOptions" v-model="dayTime"
              type="datetimerange" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" unlink-panels
              range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :clearable="false">
            </el-date-picker>

            <el-button class="el-icon-download mr_10" type="success" @click="handleExport">测点下载</el-button>
            <el-button class="el-icon-download" type="success" @click="handleExport2">同组下载</el-button>
          </el-row>
          <PointList :dataSource="dataSource" :protocol="protocol" :loading="loading" :total="total"
            :currentPage="currentPage" :pageSize="pageSize" @handleCurrentChange="handleCurrentChange"
            @handleSizeChange="handleSizeChange"></PointList>
        </el-tab-pane>

        <el-tab-pane label="最新数据">
          <New></New>
        </el-tab-pane>

        <el-tab-pane label="历史监测数据">
          <el-row>
            <el-select v-model="itemType" filterable placeholder="选择检测项 查看下表最新数据">
              <el-option v-for="item in allGroupInfo" :key="item.itemType" :label="item.deviceProtocolName"
                :value="item.itemType"> </el-option>
            </el-select>

            <el-select style="margin-left: 10px" v-model="itemId" filterable placeholder="查看数据曲线">
              <el-option v-for="item in itemList" :key="item.pkItemId" :label="item.pointName" :value="item.pkItemId">
              </el-option>
            </el-select>

            <el-button class="el-icon-search mr_10" @click="findPointDataFunNew">测点查询</el-button>
            <el-button class="el-icon-document-copy mr_10" @click="findPointDataByGroupFunNew">同组查询</el-button>

            <el-date-picker class="mr_10" @change="findPointHistoryDataFun" :picker-options="pickerOptions"
              v-model="dayTime" type="datetimerange" unlink-panels format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"
              :clearable="false">
            </el-date-picker>

            <el-button class="el-icon-download mr_10" type="success" @click="handleExportHist">测点下载</el-button>
            <el-button class="el-icon-download" type="success" @click="handleExport2Hist">同组下载</el-button>

            <el-button type="success" icon="el-icon-download" @click="downloadReport('V2')">报告下载(v2&2.5)</el-button>
            <el-button type="success" icon="el-icon-download" @click="downloadReport('V3')">报告下载(v3)</el-button>
          </el-row>

          <PointList ref="myPoint" :dataSource="dataSource" :selection="isAbnormal" :protocol="protocol"
            :loading="loading" :total="total" :currentPage="currentPage" :pageSize="pageSize"
            @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"
            @handleSelectionChange="handleSelectionChange" @clearSelect="clearSelect"></PointList>
        </el-tab-pane>
        <el-tab-pane label="历史原始数据">
          <el-row>
            <el-select v-model="itemType" filterable placeholder="选择检测项 查看下表最新数据">
              <el-option v-for="item in allGroupInfo2" :key="item.itemType" :label="item.deviceProtocolName"
                :value="item.itemType"> </el-option>
            </el-select>

            <el-select style="margin-left: 10px" v-model="itemId" filterable placeholder="查看数据曲线">
              <el-option v-for="item in itemList" :key="item.pkItemId" :label="item.pointName" :value="item.pkItemId">
              </el-option>
            </el-select>

            <el-button class="el-icon-search mr_10" @click="findPointDataFunNew">测点查询</el-button>
            <el-button class="el-icon-document-copy mr_10" @click="findPointDataByGroupFunNew">同组查询</el-button>

            <el-date-picker class="mr_10" @change="findPointHistoryDataFun" :picker-options="pickerOptions"
              v-model="dayTime" type="datetimerange" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
              unlink-panels range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :clearable="false">
            </el-date-picker>

            <el-button class="el-icon-download mr_10" type="success" @click="handleExportHist">测点下载</el-button>
            <el-button class="el-icon-download" type="success" @click="handleExport2Hist">同组下载</el-button>
          </el-row>
          <PointList :dataSource="dataSource" :protocol="protocol" :loading="loading" :total="total"
            :currentPage="currentPage" :pageSize="pageSize" @handleCurrentChange="handleCurrentChange"
            @handleSizeChange="handleSizeChange"></PointList>
        </el-tab-pane>
      </el-tabs>
    </el-scrollbar>
  </div>
</template>

<script>
import PointList from '@/components/PointList.vue'
import New from './New.vue'
import { findAllPoint } from '@/api/Point.js'
import { findAllPointV3 } from '@/api/PointV3.js'
import { findPointData, findPointDataByGroup, findPointHistoryData, findPointHistoryDataByGroup } from '@/api/data.js'
import { abnormalValueDel } from '@/api/Alarm.js'

import '@/utils/time.js'

const dataSource = []
const allGroupInfo = [
  {
    deviceProtocolName: 'V_沉降监测(V2.5)',
    deviceProtocol: 'HighMoldLaserVItem',
    groupName: '沉降',
    itemType: 'V',
    nodeType: 'VF',
    data: [],
  },
  {
    deviceProtocolName: 'F_轴力监测(V2.5)',
    deviceProtocol: 'HighMoldAxialFItem',
    groupName: '轴力',
    itemType: 'F',
    nodeType: 'VF',
    data: [],
  },
  {
    deviceProtocolName: 'L_倾角监测(V2.5)',
    deviceProtocol: 'HighMoldInclinationLItem',
    groupName: '倾角',
    itemType: 'L',
    nodeType: 'HL',
    data: [],
  },
  {
    deviceProtocolName: 'H_水平位移监测(V2.5)',
    deviceProtocol: 'HighMoldLaserHItem',
    groupName: '位移',
    itemType: 'H',
    nodeType: 'HL',
    data: [],
  },
  {
    deviceProtocolName: 'V_沉降监测(V3)',
    deviceProtocol: 'HighMoldLaserVItemV3',
    groupName: '沉降',
    itemType: 'VV3',
    nodeType: 'VF',
    versions: 3,
    data: [],
  },
  {
    deviceProtocolName: 'F_轴力监测(V3)',
    deviceProtocol: 'HighMoldAxialFItemV3',
    groupName: '轴力',
    itemType: 'FV3',
    nodeType: 'VF',
    versions: 3,
    data: [],
  },
  {
    deviceProtocolName: 'L_倾角监测(V3)',
    deviceProtocol: 'HighMoldInclinationLItemV3',
    groupName: '倾角',
    itemType: 'LV3',
    nodeType: 'HL',
    versions: 3,
    data: [],
  },
  {
    deviceProtocolName: 'H_水平位移监测(V3)',
    deviceProtocol: 'HighMoldLaserHItemV3',
    groupName: '位移',
    itemType: 'HV3',
    nodeType: 'HL',
    versions: 3,
    data: [],
  },
]
const allGroupInfo2 = [
  {
    deviceProtocolName: 'V_沉降监测',
    deviceProtocol: 'HighMoldLaserVItem',
    groupName: '沉降',
    itemType: 'V',
    nodeType: 'VF',
    data: [],
  },
  {
    deviceProtocolName: 'F_轴力监测',
    deviceProtocol: 'HighMoldAxialFItem',
    groupName: '轴力',
    itemType: 'F',
    nodeType: 'VF',
    data: [],
  },
  {
    deviceProtocolName: 'L_倾角监测',
    deviceProtocol: 'HighMoldInclinationLItem',
    groupName: '倾角',
    itemType: 'L',
    nodeType: 'HL',
    data: [],
  },
  {
    deviceProtocolName: 'V_沉降监测(V3)',
    deviceProtocol: 'HighMoldLaserVItemV3',
    groupName: '沉降',
    itemType: 'VV3',
    nodeType: 'VF',
    versions: 3,
    data: [],
  },
  {
    deviceProtocolName: 'F_轴力监测(V3)',
    deviceProtocol: 'HighMoldAxialFItemV3',
    groupName: '轴力',
    itemType: 'FV3',
    nodeType: 'VF',
    versions: 3,
    data: [],
  },
  {
    deviceProtocolName: 'L_倾角监测(V3)',
    deviceProtocol: 'HighMoldInclinationLItemV3',
    groupName: '倾角',
    itemType: 'LV3',
    nodeType: 'HL',
    versions: 3,
    data: [],
  },
]
let defaultStart = new Date()
defaultStart.setTime(defaultStart.getTime() - 3600 * 1000 * 24 * 7)
defaultStart = defaultStart.format('yyyy-MM-dd hh:mm:ss')

export default {
  data() {
    return {
      isAbnormal: false,
      ids: [],

      dataSource,
      allGroupInfo,
      allGroupInfo2,
      protocol: 'HighMoldLaserVItem',
      itemType: 'V',
      currentPage: 0,
      pageSize: 10,
      total: null,
      loading: true,
      // 数据
      itemList: [],
      itemId: null,
      type: 'Result',
      dayTime: [defaultStart, new Date().format('yyyy-MM-dd hh:mm:ss')],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近两小时',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 2)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 3)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },

      ORType: null,
      isGroup: false,
    }
  },
  watch: {
    itemType(e) {
      this.getSelectData(e)
    },

    itemId() {
      if (this.type === 'Result' || this.type === 'Origin') {
        this.findPointDataFun()
      } else if (this.type === 'R' || this.type === 'O') {
        this.findPointHistoryDataFun()
      }
    },
  },
  components: { PointList, New },
  methods: {
    downloadReport(version) {
      window.location.href = window.location.origin + '/api/historical/getReport?taskId=' + this.$route.params.projectId + '&version=' + version
    },
    // 时间刷新
    setNewTimeFun() {
      let defaultStart = new Date()
      defaultStart.setTime(defaultStart.getTime() - 3600 * 1000 * 24 * 7)
      defaultStart = defaultStart.format('yyyy-MM-dd hh:mm:ss')
      this.dayTime = [defaultStart, new Date().format('yyyy-MM-dd hh:mm:ss')]
    },
    clearLoading() {
      const timer = setTimeout(() => {
        this.loading = false
        clearTimeout(timer)
      }, 300)
    },
    handleChangeTime() {
      this.findDataListByIsGroupAndIsHisTory()
    },
    // 是否同组  是否历史 是否处理&原始 查询 数据
    findDataListByIsGroupAndIsHisTory() {
      if (this.isGroup) {
        if (this.type === 'Result' || this.type === 'Origin') {
          this.findPointDataByGroupFun()
        } else if (this.type === 'R' || this.type === 'O') {
          this.findPointHistoryDataByGroupFun()
        }
      } else {
        if (this.type === 'Result' || this.type === 'Origin') {
          this.findPointDataFun()
        } else if (this.type === 'R' || this.type === 'O') {
          this.findPointHistoryDataFun()
        }
      }
    },
    //
    findPointDataFunNew() {
      this.isGroup = false
      this.setNewTimeFun()
      this.currentPage = 0
      if (this.type === 'Result' || this.type === 'Origin') {
        this.findPointDataFun()
      } else if (this.type === 'R' || this.type === 'O') {
        this.findPointHistoryDataFun()
      }
    },
    findPointDataByGroupFunNew() {
      this.isGroup = true

      this.setNewTimeFun()
      this.currentPage = 0
      if (this.type === 'Result' || this.type === 'Origin') {
        this.findPointDataByGroupFun()
      } else if (this.type === 'R' || this.type === 'O') {
        this.findPointHistoryDataByGroupFun()
      }
    },

    async handleAbnormal() {
      console.log(this.ids)
      const itemtype = this.itemType.includes('V3') ? this.itemType.replace('V3', '') : this.itemType
      const { data: res } = await abnormalValueDel(itemtype, this.ids.join())

      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })

      this.ids = []

      this.isAbnormal = false

      this.findPointDataFun()
    },

    // 查询任务所有测点
    async findAllPointFun() {
      const { data: res } = await findAllPoint(this.$route.params.projectId - 0)

      // 查询3代测点
      const { data: resV3 } = await findAllPointV3(this.$route.params.projectId - 0)
      console.log('获取测点相关信息', res.data)
      console.log('获取3代测点相关信息', resV3.data)

      this.allGroupInfo2[0].data = this.allGroupInfo[0].data = res.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null
        return item.monitoringType === '沉降监测'
      })
      this.allGroupInfo2[1].data = this.allGroupInfo[1].data = res.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null
        return item.monitoringType === '轴力监测'
      })
      this.allGroupInfo2[2].data = this.allGroupInfo[2].data = res.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null
        return item.monitoringType === '倾角监测'
      })
      // 位移没有原始数据
      this.allGroupInfo[3].data = res.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null
        return item.monitoringType === '位移监测'
      })

      this.allGroupInfo2[3].data = this.allGroupInfo[4].data = resV3.data.filter((item) => {
        return item.monitoringType === '沉降监测'
      })
      this.allGroupInfo2[4].data = this.allGroupInfo[5].data = resV3.data.filter((item) => {
        return item.monitoringType === '轴力监测'
      })
      this.allGroupInfo2[5].data = this.allGroupInfo[6].data = resV3.data.filter((item) => {
        return item.monitoringType === '倾角监测'
      })
      // 位移没有原始数据
      this.allGroupInfo[7].data = resV3.data.filter((item) => {
        return item.monitoringType === '位移监测'
      })

      this.getSelectData(this.allGroupInfo[0].itemType)
    },
    // 数据
    async findPointDataFun() {
      if (!this.dayTime || !this.dayTime[0] === null || this.dayTime[1] === null) {
        this.setNewTimeFun()
        return
      }
      if (this.itemType === 'H' && this.type === 'Origin') {
        this.itemType = 'F'
        return
      }

      this.loading = true
      const { data: res } = await findPointData(this.itemType, this.type, this.$route.params.projectId - 0, this.itemId, this.currentPage, this.pageSize, this.dayTime[0], this.dayTime[1])
      this.dataSource = res.data.list
      this.total = res.data.total
      res.data.list.forEach((item) => {
        item.pointName = item.point ? item.point.pointName : null
      })
      // 为防止加载速度太快 用户多次点击 设置一个过度时间
      this.clearLoading()
    },
    // 同组
    async findPointDataByGroupFun() {
      if (!this.dayTime || !this.dayTime[0] === null || this.dayTime[1] === null) {
        this.setNewTimeFun()
        return
      }
      if (this.itemType === 'H' && this.type === 'Origin') {
        this.itemType = 'F'
        return
      }
      this.loading = true
      const { data: res } = await findPointDataByGroup(this.itemType, this.type, this.$route.params.projectId - 0, this.currentPage, this.pageSize, this.dayTime[0], this.dayTime[1])
      this.dataSource = res.data.list
      this.total = res.data.total
      res.data.list.forEach((item) => {
        item.pointName = item.point ? item.point.pointName : null
      })
      this.clearLoading()
    },
    // 历史数据
    async findPointHistoryDataFun() {
      if (!this.dayTime || !this.dayTime[0] === null || this.dayTime[1] === null) {
        this.setNewTimeFun()
        return
      }
      if (this.itemType === 'H' && this.type === 'Origin') {
        this.itemType = 'F'
        return
      }

      this.loading = true
      const { data: res } = await findPointHistoryData(this.itemType, this.type, this.$route.params.projectId - 0, this.itemId, this.currentPage, this.pageSize, this.dayTime[0], this.dayTime[1])
      this.dataSource = res.data.list
      this.total = res.data.total
      res.data.list.forEach((item) => {
        item.pointName = item.point ? item.point.pointName : null
      })
      // 为防止加载速度太快 用户多次点击 设置一个过度时间
      this.clearLoading()
    },
    // 历史数据同组
    async findPointHistoryDataByGroupFun() {
      if (!this.dayTime || !this.dayTime[0] === null || this.dayTime[1] === null) {
        this.setNewTimeFun()
        return
      }
      if (this.itemType === 'H' && this.type === 'Origin') {
        this.itemType = 'F'
        return
      }
      this.loading = true
      const { data: res } = await findPointHistoryDataByGroup(this.itemType, this.type, this.$route.params.projectId - 0, this.currentPage, this.pageSize, this.dayTime[0], this.dayTime[1])
      this.dataSource = res.data.list
      this.total = res.data.total
      res.data.list.forEach((item) => {
        item.pointName = item.point ? item.point.pointName : null
      })
      this.clearLoading()
    },
    // async downloadPointDataFun() {
    //   const { data: res } = await downloadPointData(this.$route.params.projectId - 0, this.itemId, this.ORType)
    //   console.log(res.data)
    // },
    // 初始化数据
    async init() {
      await this.findAllPointFun()
      await this.findPointDataFun()
    },

    getSelectData(e) {
      console.log(e, this.allGroupInfo)
      const data = this.allGroupInfo.filter((item) => {
        return item.itemType === e
      })

      console.log('获取的data', data)
      this.itemList = data[0].data

      this.protocol = data[0].deviceProtocol

      if (this.itemList.length === 0) {
        this.$message({
          message: '该监测项无测点，请切换检测项重试！！',
          type: 'warning',
        })
        return
      }

      this.itemId = this.itemList[0].pkItemId
      console.log(this.itemList, this.protocol, this.itemId)
    },

    selectTab(e) {
      const index = e.index - 0
      // index === 1 ? (this.type = 'Origin') : (this.type = 'Result')
      switch (index) {
        case 1:
          this.type = 'Origin'
          break
        case 0:
          this.type = 'Result'
          break
        case 2:
          this.type = 'New'
          break
        case 3:
          this.type = 'R'
          break
        case 4:
          this.type = 'O'
          this.itemType = this.itemType === 'H' ? 'V' : this.itemType
          break
      }
      this.loading = true
      if (this.type === 'New') {
        // console.log('此处展示最新数据')
        // this.findTaskNewDataFun()
      } else if (this.type === 'R' || this.type === 'O') {
        this.findPointHistoryDataFun()
      } else {
        this.findPointDataFun()
      }
    },

    handleSelectionChange(e) {
      console.log(e)
      this.ids = []
      e.forEach((item) => {
        this.ids.push(item.pkResultId)
      })
    },
    clearSelect() {
      this.isAbnormal = false
      this.$refs.myPoint.$refs.pointList.$refs.multipleTable.clearSelection()
    },

    // 修改 表格条数
    handleSizeChange(val) {
      console.log('每页' + val + '条')
      this.pageSize = val
      this.findDataListByIsGroupAndIsHisTory()
    },

    // 修改 表格页数
    handleCurrentChange(val) {
      console.log('第' + val + '页')
      this.currentPage = val
      this.findDataListByIsGroupAndIsHisTory()
    },

    // 测点下载
    handleExport() {
      console.log(window.location.origin)
      this.$confirm('确定下载该文件吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          console.log(window.location.origin)
          console.log('123', this.itemType)
          // version
          switch (this.itemType) {
            case 'V':
              this.ORType = this.type === 'Result' ? 'VR' : 'VO'
              window.location.href = window.location.origin + '/api/data/upload?taskId=' + (this.$route.params.projectId - 0) + '&itemId=' + this.itemId + '&type=' + this.ORType
              break
            case 'VV3':
              this.ORType = this.type === 'Result' ? 'VR' : 'VO'
              window.location.href = window.location.origin + '/api/data/upload?taskId=' + (this.$route.params.projectId - 0) + '&itemId=' + this.itemId + '&type=' + this.ORType + '&version=V3'
              break
            case 'F':
              this.ORType = this.type === 'Result' ? 'FR' : 'FO'
              window.location.href = window.location.origin + '/api/data/upload?taskId=' + (this.$route.params.projectId - 0) + '&itemId=' + this.itemId + '&type=' + this.ORType
              break
            case 'FV3':
              this.ORType = this.type === 'Result' ? 'FR' : 'FO'
              window.location.href = window.location.origin + '/api/data/upload?taskId=' + (this.$route.params.projectId - 0) + '&itemId=' + this.itemId + '&type=' + this.ORType + '&version=V3'
              break
            case 'L':
              this.ORType = this.type === 'Result' ? 'LR' : 'LO'
              window.location.href = window.location.origin + '/api/data/upload?taskId=' + (this.$route.params.projectId - 0) + '&itemId=' + this.itemId + '&type=' + this.ORType
              break
            case 'LV3':
              this.ORType = this.type === 'Result' ? 'LR' : 'LO'
              window.location.href = window.location.origin + '/api/data/upload?taskId=' + (this.$route.params.projectId - 0) + '&itemId=' + this.itemId + '&type=' + this.ORType + '&version=V3'
              break
            case 'H':
              this.ORType = 'HR'
              window.location.href = window.location.origin + '/api/data/upload?taskId=' + (this.$route.params.projectId - 0) + '&itemId=' + this.itemId + '&type=' + this.ORType
              break
            case 'HV3':
              this.ORType = 'HR'
              window.location.href = window.location.origin + '/api/data/upload?taskId=' + (this.$route.params.projectId - 0) + '&itemId=' + this.itemId + '&type=' + this.ORType + '&version=V3'
              break
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消下载',
          })
        })
    },
    // 同组下载
    handleExport2() {
      console.log(window.location.origin)
      this.$confirm('确定下载该文件吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {

          switch (this.itemType) {
            case 'V':
              this.ORType = this.type === 'Result' ? 'VR' : 'VO'
              window.location.href = window.location.origin + '/api/data/upload?taskId=' + (this.$route.params.projectId - 0) + '&type=' + this.ORType
              break
            case 'VV3':
              this.ORType = this.type === 'Result' ? 'VR' : 'VO'
              window.location.href = window.location.origin + '/api/data/upload?taskId=' + (this.$route.params.projectId - 0) + '&type=' + this.ORType + '&version=V3'
              break
            case 'F':
              this.ORType = this.type === 'Result' ? 'FR' : 'FO'
              window.location.href = window.location.origin + '/api/data/upload?taskId=' + (this.$route.params.projectId - 0) + '&type=' + this.ORType
              break
            case 'FV3':
              this.ORType = this.type === 'Result' ? 'FR' : 'FO'
              window.location.href = window.location.origin + '/api/data/upload?taskId=' + (this.$route.params.projectId - 0) + '&type=' + this.ORType + '&version=V3'
              break
            case 'L':
              this.ORType = this.type === 'Result' ? 'LR' : 'LO'
              window.location.href = window.location.origin + '/api/data/upload?taskId=' + (this.$route.params.projectId - 0) + '&type=' + this.ORType
              break
            case 'LV3':
              this.ORType = this.type === 'Result' ? 'LR' : 'LO'
              window.location.href = window.location.origin + '/api/data/upload?taskId=' + (this.$route.params.projectId - 0) + '&type=' + this.ORType + '&version=V3'
              break
            case 'H':
              this.ORType = 'HR'
              window.location.href = window.location.origin + '/api/data/upload?taskId=' + (this.$route.params.projectId - 0) + '&type=' + this.ORType
              break
            case 'HV3':
              this.ORType = 'HR'
              window.location.href = window.location.origin + '/api/data/upload?taskId=' + (this.$route.params.projectId - 0) + '&type=' + this.ORType + '&version=V3'
              break
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消下载',
          })
        })
    },
    // 测点下载 历史数据
    handleExportHist() {
      console.log(window.location.origin)
      this.$confirm('确定下载该文件吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {

          switch (this.itemType) {
            case 'V':
              this.ORType = this.type === 'R' ? 'VR' : 'VO'
              window.location.href = window.location.origin + '/api/historical/upload?taskId=' + (this.$route.params.projectId - 0) + '&itemId=' + this.itemId + '&type=' + this.ORType
              break
            case 'F':
              this.ORType = this.type === 'R' ? 'FR' : 'FO'
              window.location.href = window.location.origin + '/api/historical/upload?taskId=' + (this.$route.params.projectId - 0) + '&itemId=' + this.itemId + '&type=' + this.ORType
              break
            case 'L':
              this.ORType = this.type === 'R' ? 'LR' : 'LO'
              window.location.href = window.location.origin + '/api/historical/upload?taskId=' + (this.$route.params.projectId - 0) + '&itemId=' + this.itemId + '&type=' + this.ORType
              break
            case 'H':
              this.ORType = 'HR'
              window.location.href = window.location.origin + '/api/historical/upload?taskId=' + (this.$route.params.projectId - 0) + '&itemId=' + this.itemId + '&type=' + this.ORType
              break

            case 'VV3':
              this.ORType = this.type === 'R' ? 'VR' : 'VO'
              window.location.href = window.location.origin + '/api/historical/upload?taskId=' + (this.$route.params.projectId - 0) + '&itemId=' + this.itemId + '&type=' + this.ORType + '&version=V3'
              break
            case 'FV3':
              this.ORType = this.type === 'R' ? 'FR' : 'FO'
              window.location.href = window.location.origin + '/api/historical/upload?taskId=' + (this.$route.params.projectId - 0) + '&itemId=' + this.itemId + '&type=' + this.ORType + '&version=V3'
              break
            case 'LV3':
              this.ORType = this.type === 'R' ? 'LR' : 'LO'
              window.location.href = window.location.origin + '/api/historical/upload?taskId=' + (this.$route.params.projectId - 0) + '&itemId=' + this.itemId + '&type=' + this.ORType + '&version=V3'
              break
            case 'HV3':
              this.ORType = 'HR'
              window.location.href = window.location.origin + '/api/historical/upload?taskId=' + (this.$route.params.projectId - 0) + '&itemId=' + this.itemId + '&type=' + this.ORType + '&version=V3'
              break
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消下载',
          })
        })
    },
    // 同组下载 历史数据
    handleExport2Hist() {
      console.log(this.itemType,window.location.origin)
      this.$confirm('确定下载该文件吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          switch (this.itemType) {
            case 'V':
              this.ORType = this.type === 'R' ? 'VR' : 'VO'
              window.location.href = window.location.origin + '/api/historical/upload?taskId=' + (this.$route.params.projectId - 0) + '&type=' + this.ORType
              break
            case 'F':
              this.ORType = this.type === 'R' ? 'FR' : 'FO'
              window.location.href = window.location.origin + '/api/historical/upload?taskId=' + (this.$route.params.projectId - 0) + '&type=' + this.ORType
              break
            case 'L':
              this.ORType = this.type === 'R' ? 'LR' : 'LO'
              window.location.href = window.location.origin + '/api/historical/upload?taskId=' + (this.$route.params.projectId - 0) + '&type=' + this.ORType
              break
            case 'H':
              this.ORType = 'HR'
              window.location.href = window.location.origin + '/api/historical/upload?taskId=' + (this.$route.params.projectId - 0) + '&type=' + this.ORType
              break

            case 'VV3':
            
              this.ORType = this.type === 'R' ? 'VR' : 'VO'
              window.location.href = window.location.origin + '/api/historical/upload?taskId=' + (this.$route.params.projectId - 0) + '&type=' + this.ORType+ '&version=V3'
              break
            case 'FV3':
              this.ORType = this.type === 'R' ? 'FR' : 'FO'
              window.location.href = window.location.origin + '/api/historical/upload?taskId=' + (this.$route.params.projectId - 0) + '&type=' + this.ORType+ '&version=V3'
              break
            case 'LV3':
              this.ORType = this.type === 'R' ? 'LR' : 'LO'
              window.location.href = window.location.origin + '/api/historical/upload?taskId=' + (this.$route.params.projectId - 0) + '&type=' + this.ORType+ '&version=V3'
              break
            case 'HV 3':
              this.ORType = 'HR'
              window.location.href = window.location.origin + '/api/historical/upload?taskId=' + (this.$route.params.projectId - 0) + '&type=' + this.ORType+ '&version=V3'
              break
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消下载',
          })
        })
    },
  },
  created() {
    this.init()
  },
  mounted() { },
}
</script>

<style scoped lang="scss">
.Data {
  // width: 100%;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  padding: 10px;
  overflow: hidden;

  .el-scrollbar {
    height: 100%;
  }

  .el-scrollbar ::v-deep.el-scrollbar__wrap {
    overflow: scroll;
    height: 100% !important;
    overflow-x: hidden !important;
  }

  .el-row {
    // height: 40px;
    line-height: 40px;
    margin-bottom: 10px;

    // line-height: 40px;
    .mr_10 {
      margin-left: 10px;
    }
  }

  .el-tabs {
    ::v-deep td {
      height: 50px;
      padding: 0 !important;
    }

    ::v-deep th {
      height: 50px;
      padding: 0 !important;
    }
  }
}
</style>
