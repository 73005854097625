<template>
  <div>
    <el-form>
      <el-form-item label="报警情况:">
        <el-select v-model="value" placeholder="请选择报警级别" class="mr_20">
          <el-option label="真实警情" :value="2"> </el-option>
          <el-option label="伪报警" :value="1"> </el-option>

          <el-option label="广州住建委对接" :value="4"> </el-option>

        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="updateAlarmFun">确定</el-button>
        <el-button @click="handleClose">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { updateAlarm } from '@/api/Alarm.js'

export default {
  data() {
    return { value: 1 }
  },
  props: {
    pkAlarmId: {
      type: Number,
    },
    fkTaskId: {
      type: Number,
    },
    versions: {
      type: Number,
    },
  },
  methods: {
    async updateAlarmFun() {
      console.log('111', this.versions)
      const { data: res } = await updateAlarm(this.pkAlarmId, this.fkTaskId, this.value, this.versions)

      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      this.handleClose()

      this.$parent.$parent.$parent.$parent.$parent.findTaskAlarmFun()
    },
    handleClose() {
      this.$parent.$parent.handleClose()
    },
  },
  created() {},
  mounted() {},
}
</script>

<style scoped lang="scss">
.el-select {
  width: 250px;
}
.el-button {
  width: 120px;
}
</style>
