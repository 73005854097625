<template>
  <div class="chart">
    <div class="line_box" v-loading="loading">
      <lineChart ref="lineChart" :itemType="itemType" :tableData="tableData"></lineChart>
    </div>

    <el-row>
      <el-select v-model="itemType" filterable placeholder="请选择检测项">
        <el-option v-for="item in allGroupInfo" :key="item.itemType" :label="item.deviceProtocolName" :value="item.itemType"> </el-option>
      </el-select>

      <el-select style="margin-left: 10px" v-model="itemId" filterable placeholder="查看数据曲线">
        <el-option v-for="item in itemList" :key="item.pkItemId" :label="item.pointName" :value="item.pkItemId"> </el-option>
      </el-select>

      <el-date-picker style="margin-left: 10px" :picker-options="pickerOptions" v-model="dayTime" type="datetimerange" unlink-panels format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"> </el-date-picker>

      <el-button class="el-icon-search" style="margin-left: 10px" @click="findDataFun">查询数据</el-button>
      <el-button class="el-icon-refresh" style="margin-left: 10px" @click="findDataFunNew">刷新数据</el-button>
    </el-row>
  </div>
</template>

<script>
import lineChart from './secondChart.vue'
import { findAllPoint } from '@/api/Point.js'
import { findAllPointV3 } from '@/api/PointV3.js'
import { findPointData } from '@/api/data.js'

import '@/utils/time.js'

const allGroupInfo = [
  {
    deviceProtocolName: 'V_沉降监测(V2.5)',
    deviceProtocol: 'HighMoldLaserVItem',
    groupName: '沉降',
    itemType: 'V',
    nodeType: 'VF',
    data: [],
  },
  {
    deviceProtocolName: 'F_轴力监测(V2.5)',
    deviceProtocol: 'HighMoldAxialFItem',
    groupName: '轴力',
    itemType: 'F',
    nodeType: 'VF',
    data: [],
  },
  {
    deviceProtocolName: 'L_倾角监测(V2.5)',
    deviceProtocol: 'HighMoldInclinationLItem',
    groupName: '倾角',
    itemType: 'L',
    nodeType: 'HL',
    data: [],
  },
  {
    deviceProtocolName: 'H_水平位移监测(V2.5)',
    deviceProtocol: 'HighMoldLaserHItem',
    groupName: '位移',
    itemType: 'H',
    nodeType: 'HL',
    data: [],
  },

  {
    deviceProtocolName: 'V_沉降监测(V3)',
    deviceProtocol: 'HighMoldLaserVItemV3',
    groupName: '沉降',
    itemType: 'VV3',
    nodeType: 'VF',
    versions: 3,
    data: [],
  },
  {
    deviceProtocolName: 'F_轴力监测(V3)',
    deviceProtocol: 'HighMoldAxialFItemV3',
    groupName: '轴力',
    itemType: 'FV3',
    nodeType: 'VF',
    versions: 3,
    data: [],
  },
  {
    deviceProtocolName: 'L_倾角监测(V3)',
    deviceProtocol: 'HighMoldInclinationLItemV3',
    groupName: '倾角',
    itemType: 'LV3',
    nodeType: 'HL',
    versions: 3,
    data: [],
  },
  {
    deviceProtocolName: 'H_水平位移监测(V3)',
    deviceProtocol: 'HighMoldLaserHItemV3',
    groupName: '位移',
    itemType: 'HV3',
    nodeType: 'HL',
    versions: 3,
    data: [],
  },
]

let defaultStart = new Date()
defaultStart.setTime(defaultStart.getTime() - 3600 * 1000 * 48)
defaultStart = defaultStart.format('yyyy-MM-dd hh:mm:ss')

export default {
  components: { lineChart },
  data() {
    return {
      allGroupInfo,
      protocol: 'HighMoldLaserVItem',
      itemType: 'V',
      dayTime: [defaultStart, new Date().format('yyyy-MM-dd hh:mm:ss')],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近两小时',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 2)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 3)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      itemList: [],
      itemId: null,
      type: 'Result',
      titleText: '轴力监测曲线',
      tableData: [],
      loading: true,
    }
  },
  watch: {
    itemType(e) {
      this.getSelectData(e)
    },
    itemId() {},
  },
  methods: {
    // 查询任务所有测点
    async findAllPointFun() {
      const { data: res } = await findAllPoint(this.$route.params.projectId - 0)
      const { data: resV3 } = await findAllPointV3(this.$route.params.projectId - 0)
      // console.log('获取测点相关信息', res.data)
      this.allGroupInfo[0].data = res.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null
        return item.monitoringType === '沉降监测'
      })
      this.allGroupInfo[1].data = res.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null
        return item.monitoringType === '轴力监测'
      })
      this.allGroupInfo[2].data = res.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null
        return item.monitoringType === '倾角监测'
      })
      this.allGroupInfo[3].data = res.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null
        return item.monitoringType === '位移监测'
      })

      this.allGroupInfo[4].data = resV3.data.filter((item) => {
        return item.monitoringType === '沉降监测'
      })
      this.allGroupInfo[5].data = resV3.data.filter((item) => {
        return item.monitoringType === '轴力监测'
      })
      this.allGroupInfo[6].data = resV3.data.filter((item) => {
        return item.monitoringType === '倾角监测'
      })
      this.allGroupInfo[7].data = resV3.data.filter((item) => {
        return item.monitoringType === '位移监测'
      })

      this.getSelectData(this.allGroupInfo[0].itemType)
    },
    findDataFunNew() {
      let defaultStart = new Date()
      defaultStart.setTime(defaultStart.getTime() - 3600 * 1000 * 24 * 2)
      defaultStart = defaultStart.format('yyyy-MM-dd hh:mm:ss')
      this.dayTime = [defaultStart, new Date().format('yyyy-MM-dd hh:mm:ss')]
      this.findDataFun()
    },
    async findDataFun() {
      this.loading = true
      const { data: res } = await findPointData(this.itemType, this.type, this.$route.params.projectId - 0, this.itemId, 0, 99999, this.dayTime[0], this.dayTime[1])
      this.tableData = res.data.list
      this.loading = false
    },

    async init() {
      await this.findAllPointFun()
      await this.findDataFun()
    },
    getSelectData(e) {
      const data = this.allGroupInfo.filter((item) => {
        return item.itemType === e
      })

      this.itemList = data[0].data
      this.protocol = data[0].deviceProtocol
      if (this.itemList.length === 0) {
        this.$message({
          message: '该监测项无测点，请切换检测项重试！！',
          type: 'warning',
        })
        return
      }
      this.itemId = this.itemList[0].pkItemId

      this.findDataFun()
    },
  },
  created() {
    this.init()
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.chart {
  //   .el-scrollbar {
  //   height: 100%;
  // }

  // .el-scrollbar ::v-deep.el-scrollbar__wrap {
  //   overflow: scroll;
  //   height: 100% !important;
  //   overflow-x: hidden !important;
  // }
}
.line_box {
  width: 100%;
  height: 600px;
}

.lineChart {
  width: 100%;
  height: 100%;
}

.el-row {
  padding-top: 20px;
  padding-left: 50px;
}
</style>
