<template>
  <el-tabs type="card">
    <el-tab-pane label="对接配置">
      <div>
        <el-empty description="当前没有对接配置哦" v-show="!isFlag" ref="ruleForm">
          <el-button type="text" @click="isFlag = true">立即创建</el-button>
        </el-empty>
        <div v-show="isFlag">

          <el-form ref="form" :model="form" :rules="rules" label-width="150px">
            <el-form-item label="工程编号" prop="projectCode">
              <el-input v-model="form.projectCode"></el-input>
            </el-form-item>
            <el-form-item label="分部编号" prop="brachCode">
              <el-input v-model="form.brachCode"></el-input>
            </el-form-item>
            <el-form-item label="设备编号" prop="deviceName">
              <el-input v-model="form.deviceName"></el-input>
            </el-form-item>

            <!-- <el-form-item label="appKey" prop="appKey">
              <el-input v-model="form.appKey"></el-input>
            </el-form-item>

            <el-form-item label="appSecret" prop="appSecret">
              <el-input v-model="form.appSecret"></el-input>
            </el-form-item> -->


            <el-form-item label="appKey" prop="appKey1">
              <el-input value="衡安云联" disabled></el-input>
            </el-form-item>

            <el-form-item label="appSecret" prop="appSecret1">
              <el-input value="衡安云联" disabled></el-input>
            </el-form-item>


            <el-form-item label="操作">
              <el-button type="primary" @click="submitForm" v-show="form.pkConfigId">修改 <i
                  class="el-icon-edit"></i></el-button>
              <el-button type="primary" @click="submitForm" v-show="!form.pkConfigId">立即创建 <i
                  class="el-icon-plus"></i></el-button>

              <el-button @click="syncPointFunc" type="primary" v-show="form.pkConfigId">同步 <i
                  class="el-icon-refresh"></i></el-button>
              <el-button @click="handDel" type="danger" v-show="form.pkConfigId">删除 <i
                  class="el-icon-delete"></i></el-button>
            </el-form-item>

            <el-form-item label="日志上传">
              <el-button @click="uploadStartLogFunc" type="success" v-show="form.pkConfigId"><i
                  class="el-icon-upload2"></i>
                设备开始监测</el-button>
              <el-button @click="uploadStopLogFunc" type="success" v-show="form.pkConfigId"><i
                  class="el-icon-upload2"></i>
                设备及分部停止监测</el-button>
            </el-form-item>
          </el-form>
        </div>

      </div>
    </el-tab-pane>
    <!-- <el-tab-pane label="对接日志">
      <el-button type="primary" size="small" icon="el-icon-refresh-left" v-if="tableData.length > 0"
        @click="findDockingLog">刷新</el-button>
      <el-table :data="tableData" style="width: 100%" v-if="tableData.length > 0" v-loading="loading">
        <el-table-column prop="createTime" label="时间"> </el-table-column>
        <el-table-column prop="dockingLog" label="日志"> </el-table-column>
      </el-table>
      <el-empty description="无日志" v-else>
        <el-button type="primary" size="small" icon="el-icon-refresh-left" @click="findDockingLog">刷新</el-button>
      </el-empty>
    </el-tab-pane> -->
  </el-tabs>
</template>

<script>
import {
  findGZJWDockingByTaskId,
  addGZJWDocking,
  updateGZJWDocking,
  deleteGZJWDocking,
  // findDockingLogByTaskId,
  syncPoint,
  uploadStartLog, uploadStopLog
} from '@/api/docking/gzjw'
const form = {
  projectCode: '',
  brachCode: '',
  deviceName: '',
  appKey: 'yunliang&1010',
  appSecret: '3884e60630ec717861dc1607a6a30f98',
}
export default {
  data() {
    return {
      tableData: [],
      loading: false,
      isFlag: false,
      send: {},
      form,
      isUploading: false,
      rules: {
        projectCode: [{ required: true, message: '工程编号不能为空' }],
        brachCode: [{ required: true, message: '分部编号不能为空' }],
        deviceName: [{ required: true, message: '设备编号不能为空' }],
        appKey: [{ required: true, message: 'appKey不能为空' }],
        appSecret: [{ required: true, message: 'appSecret不能为空' }],
      },
    }
  },
  props: {
    pkTaskId: {
      type: Number,
    },
  },
  methods: {
    async findGZJWDockingByTaskIdFun() {
      const { data: res } = await findGZJWDockingByTaskId(this.pkTaskId)
      console.log(res.data)
      this.isFlag = res.data.length === 0 ? false : true

      if (res.data.length > 0) {
        this.form = res.data[0]
      }
    },

    async submitDockingInfo() {
      const { projectCode, brachCode, deviceName, appKey, appSecret, } = this.form
      const form = { projectCode, brachCode, deviceName, appKey, appSecret, fkTaskId: this.pkTaskId }

      const { data: res } = this.form.pkConfigId ? await updateGZJWDocking({ ...form, pkConfigId: this.form.pkConfigId }) : await addGZJWDocking(form)
      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      res.code === 200 ? this.findGZJWDockingByTaskIdFun() : null
    },
    async deleteGZJWDockingFunc() {
      const { data: res } = await deleteGZJWDocking(this.form.pkConfigId)
      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      res.code === 200 ? this.findGZJWDockingByTaskIdFun() : null
    },

    submitForm() {
      if (this.form.projectCode === '' || this.form.brachCode === '' || this.form.deviceName === '' || this.form.appKey === '' || this.form.appSecret === '') {
        return
      }
      this.submitDockingInfo()
    },


    async handDel() {
      try {
        await this.$confirm('此操作将删除该配置, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
        // 用户确认删除
        await this.deleteGZJWDockingFunc();
      } catch (error) {
        // 用户取消删除
        this.$message({
          type: 'info',
          message: '已取消删除',
        });
      }
    },

    // async findDockingLog() {
    //   this.loading = true
    //   const { data: res } = await findDockingLogByTaskId({ id: this.pkTaskId })
    //   this.tableData = res
    //   this.loading = false
    // },
    async syncPointFunc() {
      try {
        await this.$confirm('请务必确认信息无误后同步,同步后无法修改！！！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });

        const { data: res } = await syncPoint(this.form.pkConfigId)
        this.$message({
          message: res.message,
          type: res.code === 200 ? 'success' : 'error',
        })

      } catch (error) {
        this.$message({
          type: 'info',
          message: '操作取消',
        });
      }


    },
    async uploadStartLogFunc() {
      const { data: res } = await uploadStartLog(this.form.pkConfigId)
      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
    },
    async uploadStopLogFunc() {
      const { data: res } = await uploadStopLog(this.form.pkConfigId)
      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
    }
  },
  created() {
    this.findGZJWDockingByTaskIdFun()
  },
  mounted() { },
}
</script>

<style scoped lang="scss"></style>
